import React, { useState, useEffect } from 'react';

const MusixmatchLyrics = ({ apiKey, trackId }) => {
    const [lyrics, setLyrics] = useState('');
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchLyrics = async () => {
            const url = `https://api.musixmatch.com/ws/1.1/track.lyrics.get?track_id=${trackId}&apikey=${apiKey}`;

            try {
                const response = await fetch(url);
                const data = await response.json();
                
                if (data.message.header.status_code !== 200) {
                    throw new Error('Failed to fetch lyrics');
                }

                setLyrics(data.message.body.lyrics.lyrics_body);
            } catch (error) {
                setError(error.message);
            }
        };

        fetchLyrics();
    }, [apiKey, trackId]);

    if (error) {
        return <div>Error: {error}</div>;
    }

    return (
        <div>
            <h3>Lyrics</h3>
            <p>{lyrics || 'Loading...'}</p>
        </div>
    );
};

export default MusixmatchLyrics;