import React, { useState } from 'react';

const SongSearch = ({ apiKey, onTrackSelect }) => {
    const [searchTerm, setSearchTerm] = useState('');

    const handleSearch = async (event) => {
        event.preventDefault();
        const url = `https://api.musixmatch.com/ws/1.1/track.search?q_track=${encodeURIComponent(searchTerm)}&apikey=${apiKey}&page_size=10&page=1&s_track_rating=desc`;

        try {
            const response = await fetch(url);
            const data = await response.json();
            if (data.message.header.status_code !== 200) {
                throw new Error('Failed to fetch tracks');
            }

            // Pass the first track's ID to the parent component
            const trackList = data.message.body.track_list;
            if (trackList.length > 0) {
                onTrackSelect(trackList[0].track.track_id);
            } else {
                onTrackSelect(null);
            }
        } catch (error) {
            console.error('Error:', error);
        }
    };

    return (
        <form onSubmit={handleSearch}>
            <input
                type="text"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                placeholder="Enter song name"
            />
            <button type="submit">Search</button>
        </form>
    );
};

export default SongSearch;