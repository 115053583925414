import React, { useState } from 'react';
import SongSearch from '../components/SongSearch';
import MusixmatchLyrics from '../components/MusixmatchLyrics';

const App = () => {
    const apiKey = '8da0b97323b9ddef3f11e60ae3c6c40e';
    const [trackId, setTrackId] = useState(null);

    return (
        <div>
            <SongSearch apiKey={apiKey} onTrackSelect={setTrackId} />
            {trackId && <MusixmatchLyrics apiKey={apiKey} trackId={trackId} />}
        </div>
    );
};

export default App;